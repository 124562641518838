<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="流水号">
              <a-input v-model.trim="queryParam.record_no" placeholder="根据流水号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="部门">
              <a-select
                showSearch
                placeholder="请选择部门"
                style="width: 100%"
                v-model="queryParam.department"
                @change="changeDepartment"
              >
                <a-select-option v-for="op in GLOBAL.finPayDepartment" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="供应商" prop="customer_name">
              <a-select
                showSearch
                allowClear
                :filter-option="filterOption"
                ref="supplier"
                placeholder="选择供应商"
                v-model="queryParam.customer_name"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="状态">
              <a-select
                allowClear
                placeholder="请选择状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option key="3">商务待审核</a-select-option>
                <a-select-option key="9">商务已审核(待付款)</a-select-option>
                <a-select-option key="13">已付款</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="凭证号">
              <a-input v-model.trim="queryParam.cert_num" placeholder="根据凭证号搜索"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="凭证状态">
              <a-select
                allowClear
                style="width: 100%"
                placeholder="请选择凭证状态"
                v-model="queryParam['cert_status']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in certStatusOps" :key="op.value">{{ op.name }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务编号">
              <a-input v-model.trim="queryParam.business_no" placeholder="根据业务编号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam.department === 2">
            <a-form-model-item label="订单类型">
              <a-select
                allowClear
                placeholder="请选择订单类型"
                style="width: 100%"
                v-model="queryParam['order_type']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款日期">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="是否预付">
              <a-select
                allowClear
                style="width: 100%"
                v-model="queryParam['is_advance']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in isAdvanceOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <!-- <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleCheckSub('all')">核销</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleReverseCheck('all')">反核销</a-button> -->
      <a-button type="danger" :disabled="!selectedRowKeys.length" @click="batchGenerateCert">批量生成凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="batchHandleConfirm">批量确认凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="downloadYmsCostPayCert('all')" v-if="queryParam.department === 6">下载应付凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="downloadCert('all')" v-if="queryParam.department === 6">下载付款凭证</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="downloadCert('all')" v-else>下载凭证</a-button>
      <a-button type="primary" @click="purchaseInfo" v-if="queryParam.department === 8">贸易入库信息</a-button>
      <a-button type="primary" @click="exportTurnoverDateStatisticsSheet">导出周转日期统计表</a-button>
      <a-button type="primary" icon="export" @click="handleExportPayInvoices">付款供应商发票明细表</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :filterColumn="true"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      :scroll="{ x: 'max-content' }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="businessNo" slot-scope="text">
        <a-popover placement="bottom">
          {{ text[0] }} {{ text.length !== 1 ? `(${text.length})单` : null }}
          <template slot="content">
            <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
          </template>
        </a-popover>
      </div>
      <div slot="invoiceDetail" slot-scope="text">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 0">
            {{ text[0].invoice_no }}/税额:{{ text[0].tax }}/创建日:{{ moment(text[0].created_at).format('YYYY/MM/DD') }}
            {{ text.length !== 1 ? `(${text.length})单` : null }}
          </template>
          <template slot="content">
            <span style="display:block" v-for="item in text" :key="item.invoice_no">
              {{ item.invoice_no }}/税额:{{ item.tax }}/创建日:{{ moment(item.created_at).format('YYYY/MM/DD') }}
            </span>
          </template>
        </a-popover>
      </div>
      <div slot="certInfo" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{
          item.cert_num + '(' + transCertStatus(item.cert_status) + ')'
        }}</span>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a v-if="record.status === 9 || record.status === 3" @click="fundCashier(record)">资金出纳</a>
          <a @click="handleDeleteFundCashier(record)" style="margin-left:10px">删除出纳</a>
          <!-- <a
            v-if="record.status === 9 || record.status === 10"
            @click="handleCheckSub('row', record)"
            :style="record.status === 9 ? 'margin-left:15px' : ''"
          >核销</a
          >
          <a
            v-if="record.status === 7 || record.status === 10"
            @click="handleReverseCheck('row', record)"
            :style="record.status === 10 ? 'margin-left:15px' : ''"
          >反核销</a
          > -->
          <a v-if="queryParam.department === 6" @click="previewCert(record, 3)" style="margin-left: 10px">应付凭证</a>
          <a v-if="queryParam.department === 6" @click="previewCert(record, 1)" style="margin-left:10px">付款凭证</a>
          <a v-if="queryParam.department === 6 && record.is_amortize_pay && record.is_amortize_pay === '1'" @click="previewCert(record, 4)" style="margin-left: 10px">摊销凭证</a>
          <a v-else @click="previewCert(record, 1)" style="margin-left:10px">预览凭证</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="certDetailVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="certDetailVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="付款凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane
            v-for="item in certData"
            :key="item.id"
            :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'"
          >
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo(1)">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm(1)">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm(1)">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadCert">
          下载凭证
        </a-button>
        <!-- <a-button type="primary" @click="printCert"> 打印凭证 </a-button> -->
        <a-button @click="certDetailVisible = false"> 关闭 </a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="ymsCostPayCertVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="ymsCostPayCertVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="付款凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane
            v-for="item in certData"
            :key="item.id"
            :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'"
          >
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo(3)">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateYmsCostPayCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm(3)">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm(3)">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadYmsCostPayCert">
          下载凭证
        </a-button>
        <a-button @click="ymsCostPayCertVisible = false"> 关闭 </a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="ymsAmortizeCostPayCertVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="ymsAmortizeCostPayCertVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="付款凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane
            v-for="item in certData"
            :key="item.id"
            :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'"
          >
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo(4)">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateAmortizeYmsCostPayCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm(4)">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm(4)">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadYmsAmortizeCostPayCert">
          下载凭证
        </a-button>
        <a-button @click="ymsAmortizeCostPayCertVisible = false"> 关闭 </a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="certPurchaseDetailVisible"
      title="凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="certPurchaseDetailVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="入库凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane
            v-for="item in certData"
            :key="item.id"
            :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'"
          >
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo(2)">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generatePurchaseCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm(2)">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm(2)">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadPurchaseCert">
          下载凭证
        </a-button>
        <!-- <a-button type="primary" @click="printCert"> 打印凭证 </a-button> -->
        <a-button @click="certPurchaseDetailVisible = false"> 关闭 </a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="cashierVisible"
      :confirmLoading="cashierLoading"
      title="资金出纳"
      @cancel="cashierVisible = false"
      @ok="handleFundCashier"
    >
      <a-form-model layout="vertical" :model="cashierForm" :rules="cashierRules" ref="cashierForm">
        <a-row :gutter="30">
          <a-col :span="12">
            <a-form-model-item label="付款日期" prop="payment_date">
              <a-date-picker v-model="cashierForm.payment_date" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="18">
            <a-form-model-item label="付款银行" prop="payment_bank">
              <a-select
                showSearch
                ref="bank"
                :filter-option="filterOption"
                allowClear
                placeholder="请选择银行信息"
                style="width: 100%"
                v-model="cashierForm.payment_bank"
              >
                <a-select-option v-for="op in bankOps" :key="op.id">{{
                  op.bank + '/' + op.bank_account
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="printCertModelVisible"
      :confirmLoading="printCertModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印凭证"
      @cancel="printCertModelVisible = false"
    >
      <div id="printCertDataBill">
        <fms-cert-data-bill :certData="certPrintData" :name="certPrintName" />
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printCertDataBillBtn" v-print="printCertDataBill">确认打印</a-button>
        <a-button type="primary" @click="printCertModelVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="purchaseModelVisible"
      :confirmLoading="purchaseModelLoading"
      :width="1620"
      :maskClosable="false"
      title="贸易入库信息"
      :footer="null"
      @cancel="purchaseModelVisible = false"
    >
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="入库单号">
                  <a-input v-model="purchaseQueryParam.purchase_num" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="入库时间">
                  <a-range-picker @change="onPurchaseDateChange" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="供应商">
                  <a-select
                    showSearch
                    allowClear
                    :filter-option="filterOption"
                    ref="purchaseSupplier"
                    placeholder="选择供应商"
                    v-model="purchaseQueryParam.supplier_id"
                  >
                    <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="凭证号">
                  <a-input v-model="purchaseQueryParam.cert_num" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="凭证状态">
                  <a-select allowClear style="width: 100%" v-model="purchaseQueryParam.cert_status">
                    <a-select-option v-for="op in certStatusOps" :key="op.value">{{ op.name }} </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div style="margin: 10px 0">
          <a-button type="query" icon="search" @click="$refs.purchaseTable.refresh(true)">查询</a-button>
          <a-button type="primary" :disabled="!selectedPurchaseRowKeys.length" @click="downloadPurchaseCert('all')" style="margin-left:15px">下载凭证</a-button>
        </div>
        <s-table
          ref="purchaseTable"
          size="default"
          :rowKey="function(record,index) { return index }"
          :columns="purchaseColumns"
          :data="loadPurchaseData"
          :rowSelection="{ selectedRowKeys: this.selectedPurchaseRowKeys, onChange: this.onSelectPurchaseChange }"
          :scroll="{ x: 'max-content' }"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="goodInList" slot-scope="text">
            <span style="display:block" v-for="item in text" :key="item.id">
              {{ item.good_name + item.good_spec + '  ' + item.total_num + item.good_unit + ' 合计:' + item.total }}
            </span>
          </div>
          <div slot="certInfo" slot-scope="text">
            <span style="display:block">{{
              text ? text.cert_num + '(' + transCertStatus(text.cert_status) + ')' : ''
            }}</span>
          </div>
          <span slot="action" slot-scope="text, record">
            <template>
              <a @click="previewCert(record, 2)" style="margin-left:15px">预览凭证</a>
              <a @click="printSalePurchaseSheet(record)" style="margin-left:15px">入库单</a>
            </template>
          </span>
        </s-table>
      </a-card>
    </a-modal>

    <a-modal
      :visible="printSalePurchaseSheetVisible"
      :confirmLoading="printSalePurchaseSheetLoading"
      :width="1250"
      :maskClosable="false"
      title="销售入库单"
      @cancel="printSalePurchaseSheetVisible = false">
      <div id="printSalePurchaseSheetBill">
        <SalePurchaseSheetBill
          :currentData="currentData"
          :balanceData="balanceData">
        </SalePurchaseSheetBill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printSalePurchaseSheetBillBtn"
                  v-print="printSalePurchaseSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printSalePurchaseSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import {
  getBizCheckPage,
  fmsBizAuditCheck,
  fmsBizAuditUnCheck,
  getFmsMetaOption,
  delCert,
  certConfirm,
  certUnConfirm,
  downloadCertById,
  previewCertById,
  generatePayCert,
  fundCashier,
  deleteFundCashier,
  getPurchaseInfo,
  generatePurchaseCert,
  generateYmsCostPayCert,
  generateAmortizeYmsCostPayCert,
  exportPayInvoices,
  batchGeneratePayCert,
  batchCertConfirm
} from '@/api/fms'
import { getCustomer, getSupplier, downloadExcel } from '@/api/common'
import { getSalePurchaseSheet } from '@/api/wms/sale'
import { getFirstLetter } from '@/utils/util'
import debounce from 'lodash/debounce'
import { Empty } from 'ant-design-vue'
import FmsCertDataBill from '@/views/fms/bill/FmsCertDataBill'
import SalePurchaseSheetBill from '@/views/wms/sale/bill/SalePurchaseSheetBill'
export default {
  components: {
    STable,
    Empty,
    FmsCertDataBill,
    SalePurchaseSheetBill
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      printCertModelVisible: false,
      printCertModelLoading: false,
      printCertDataBill: {
        id: `printCertDataBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      certPrintData: [],
      certPrintName: '',
      invoiceModalTitle: '',
      formVisible: false,
      confirmLoading: false,
      invoice_number: [],
      bank: '',
      queryParam: {
        department: 2,
        cert_status: undefined
      },
      selectedRowKeys: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '业务编号',
          dataIndex: 'business_no',
          scopedSlots: { customRender: 'businessNo' }
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          customRender: text => {
            return text === 1 ? '后付' : '预付'
          }
        },
        {
          title: '凭证号',
          dataIndex: 'cert_relation',
          scopedSlots: { customRender: 'certInfo' }
        },
        {
          title: '供应商名称',
          dataIndex: 'customer_name'
        },
        {
          title: '付款金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '发票金额',
          dataIndex: 'amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '发票号',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '付款银行',
          dataIndex: 'bank'
        },
        {
          title: '付款账户',
          dataIndex: 'account'
        },
        {
          title: '付款时间',
          dataIndex: 'payment_date',
          customRender: text => {
            return text ? text.substring(0, 10) : ''
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          fixed: 'right'
        }
      ],
      certColumns: [
        {
          title: '摘要',
          dataIndex: 'summary',
          width: 300
        },
        {
          title: '业务编号/发票号',
          dataIndex: 'number',
          width: 120
        },
        {
          title: '一级科目',
          dataIndex: 'first_subject',
          width: 200
        },
        {
          title: '明细科目',
          dataIndex: 'detail_subject',
          width: 200
        },
        {
          title: '外币金额',
          dataIndex: 'amount',
          width: 100
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate',
          width: 100
        },
        {
          title: '金额',
          children: [
            {
              title: '借方',
              dataIndex: 'borrow',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            },
            {
              title: '贷方',
              dataIndex: 'loan',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            }
          ]
        }
      ],
      currentCertRecord: {},
      currentCertId: undefined,
      currentCert: {},
      certDetailVisible: false,
      certPurchaseDetailVisible: false,
      ymsCostPayCertVisible: false,
      ymsAmortizeCostPayCertVisible: false,
      certData: [],
      fetching: false,
      customerOps: [],
      customerInfo: undefined,
      loadData: parameter => {
        return getBizCheckPage('check_pay_list', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      bankOps: [],
      invoiceRegOps: [],
      checkFlag: '',
      checkRecord: {},
      supplierOps: [], // 供应商
      // 资金出纳
      cashierVisible: false,
      cashierLoading: false,
      cashierForm: {
        id: null, // 进行资金出纳的记录id
        payment_date: null, // 付款日期
        payment_bank: null // 付款银行
      },
      cashierRules: {
        payment_date: [{ required: true, message: '请选择付款日期', trigger: 'blur' }],
        payment_bank: [{ required: true, message: '请选择付款银行', trigger: 'blur' }]
      },
      certStatusOps: [
        {
          value: 1,
          name: '待确认'
        },
        {
          value: 2,
          name: '已确认'
        },
        {
          value: 3,
          name: '已下载'
        }
      ],
      purchaseQueryParam: {
        cert_status: undefined
      },
      purchaseModelVisible: false,
      purchaseModelLoading: false,
      purchaseColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num'
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date'
        },
        {
          title: '供应商名称',
          dataIndex: 'supplier_name'
        },
        {
          title: '凭证号',
          dataIndex: 'cert_relation',
          scopedSlots: { customRender: 'certInfo' }
        },
        {
          title: '货物信息',
          dataIndex: 'good_stock_list',
          scopedSlots: { customRender: 'goodInList' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadPurchaseData: parameter => {
        this.selectedPurchaseRowKeys = []
        return getPurchaseInfo(Object.assign(parameter, this.purchaseQueryParam))
          .then(res => {
            return res
          })
      },
      selectedPurchaseRowKeys: [],
      selectedPurchaseRows: [],
      currentData: {},
      balanceData: [],
      printSalePurchaseSheetVisible: false,
      printSalePurchaseSheetLoading: false,
      printSalePurchaseSheetBill: {
        id: `printSalePurchaseSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      orderTypeOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 },
        { name: '行政业务', value: 4 },
        { name: '拖运业务', value: 5 }
      ],
      isAdvanceOps: [
        { name: '后付', value: 1 },
        { name: '预付', value: 2 }
      ]
    }
  },
  created() {
    getFmsMetaOption('fms_bank').then(v => {
      this.bankOps = v
    })
    getSupplier({ department: null }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    moment,
    // 导出周转日期统计表
    exportTurnoverDateStatisticsSheet() {
      if (this.queryParam['payment_start_date'] && this.queryParam['payment_end_date']) {
        getBizCheckPage('exportTurnoverDateStatisticsSheet', this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        })
      } else {
        this.$message.warning('请选择要导出的付款日期')
      }
    },
    handleExportPayInvoices() {
      if (this.queryParam['payment_start_date'] && this.queryParam['payment_end_date']) {
        exportPayInvoices(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        })
      } else {
        this.$message.warning('请选择要导出的付款日期')
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['payment_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['payment_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['payment_start_date'] = null
        this.queryParam['payment_end_date'] = null
      }
    },
    printSalePurchaseSheet(record) {
      getSalePurchaseSheet({
        purchaseId: record.id
      }).then(res => {
        this.balanceData = res;
      }).finally(_ => {
        this.currentData = record;
        this.printSalePurchaseSheetVisible = true;
      });
    },
    // 资金出纳
    fundCashier(record) {
      this.cashierVisible = true
      this.cashierForm = {
        id: record.id, // 进行资金出纳的记录id
        payment_date: record.payment_date, // 付款日期
        payment_bank: record.payment_bank // 付款银行
      }
    },
    // 资金出纳提交事件
    handleFundCashier() {
      this.$refs.cashierForm.validate(valid => {
        if (valid) {
          const data = {
            id: this.cashierForm.id,
            payment_date: moment(this.cashierForm.payment_date).valueOf(),
            payment_bank: this.cashierForm.payment_bank
          }
          fundCashier(data)
            .then(_ => {
              this.$notification['success']({
                message: '提示',
                description: '操作成功.'
              })
              this.$refs.table.refresh(false)
              this.$refs.cashierForm.resetFields()
            })
            .finally(() => {
              this.cashierVisible = false
              this.cashierLoading = false
            })
        } else {
          return false
        }
      })
    },
    handleDeleteFundCashier(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除出纳信息吗？',
        onOk: () => {
          deleteFundCashier({ 'id': record.id }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.customerInfo = value
        this.queryParam.customer_name = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.queryParam.customer_name = ''
        this.customerOps = []
        this.fetching = false
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    changeDepartment() {
      this.$refs.table.refresh(true)
    },
    // 核销
    handleCheckSub(flag, record) {
      let _ids = []
      if (flag === 'all') {
        _ids = this.selectedRowKeys
      } else {
        _ids = [record.id]
      }
      const data = {
        ids: _ids
      }
      fmsBizAuditCheck(data).then(_ => {
        this.$notification['success']({
          message: '提示',
          description: '操作成功.'
        })
        this.$refs.table.refresh(false)
      })
    },
    handleReverseCheck(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要取消核销？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          fmsBizAuditUnCheck(_ids).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    // 预览凭证
    previewCert(record, type) {
      this.currentCertRecord = record
      if (type === 1) {
        // 预览应付、预付凭证
        this.previewPayCert(record)
        this.certDetailVisible = true
      } else if (type === 2) {
        // 预览贸易入库凭证
        this.previewPurchaseCert(record)
        this.certPurchaseDetailVisible = true
      } else if (type === 3) {
        // 预览堆场应付成本凭证
        this.previewYmsCostPayCert(record)
        this.ymsCostPayCertVisible = true
      } else if (type === 4) {
        // 预览堆场摊销成本凭证
        this.previewYmsAmortizeCostPayCert(record)
        this.ymsAmortizeCostPayCertVisible = true
      }
    },
    // 预览应付、预付凭证
    previewPayCert(record) {
      previewCertById({
        id: record.id,
        type: 3,
        batchNum: record.batch_num
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    // 预览贸易入库凭证
    previewPurchaseCert(record) {
      previewCertById({
        id: record.id,
        type: 4,
        batchNum: record.batch_num
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    // 预览堆场应付成本凭证
    previewYmsCostPayCert(record) {
      previewCertById({
        id: record.id,
        type: 7,
        batchNum: record.batch_num
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    // 堆场摊销成本
    previewYmsAmortizeCostPayCert(record) {
      previewCertById({
        id: record.id,
        type: 8,
        batchNum: record.batch_num
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    // 生成应付、预付凭证
    generateCert() {
      generatePayCert({
        id: this.currentCertRecord.id
      }).then(res => {
        if (res) {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewPayCert(this.currentCertRecord)
        }
      })
    },
    // 生成贸易入库凭证
    generatePurchaseCert() {
      generatePurchaseCert({
        id: this.currentCertRecord.id
      }).then(res => {
        if (res) {
          this.$message.success('已生成凭证')
          this.$refs.purchaseTable.refresh(false)
          this.previewPurchaseCert(this.currentCertRecord)
        }
      })
    },
    // 生成堆场应付成本凭证
    generateYmsCostPayCert() {
      generateYmsCostPayCert({
        id: this.currentCertRecord.id
      }).then(res => {
        if (res) {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewYmsCostPayCert(this.currentCertRecord)
        }
      })
    },
    generateAmortizeYmsCostPayCert() {
      generateAmortizeYmsCostPayCert({
        id: this.currentCertRecord.id
      }).then(res => {
        if (res) {
          this.$message.success('已生成凭证')
          this.$refs.table.refresh(false)
          this.previewYmsAmortizeCostPayCert(this.currentCertRecord)
        }
      })
    },
    batchGenerateCert() {
      this.$confirm({
        title: '确认生成凭证?',
        content: '是否要为所选的未生成凭证的单据生成凭证?',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          batchGeneratePayCert(this.selectedRowKeys).then(res => {
            this.$notification['success']({
                message: '提示',
                description: res
              })
              this.$refs.table.refresh(false)
          })
        }
      })
    },
    batchHandleConfirm() {
      if (this.selectedRowKeys.length > 0) {
        batchCertConfirm(this.selectedRowKeys).then(res => {
          if (res) {
            this.$message.success('凭证确认成功')
            this.$refs.table.refresh(false)
          }
        })
      }
    },
    // 下载应付、预付凭证
    downloadCert(flag) {
      let list = []
      if (flag === 'all') {
        list = this.selectedRowKeys
      } else {
        list.push(this.currentCertRecord.id)
      }
      downloadCertById({
        list,
        type: 3
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.table.refresh(false)
            if (flag !== 'all') {
              this.previewPayCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    // 下载贸易入库凭证
    downloadPurchaseCert(flag) {
      let list = []
      if (flag === 'all') {
        debugger
        list = this.selectedPurchaseRows.map(p => p.id)
      } else {
        list.push(this.currentCertRecord.id)
      }
      downloadCertById({
        list,
        type: 4
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.purchaseTable.refresh(false)
            if (flag !== 'all') {
              this.previewPurchaseCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    // 下载堆场应付成本凭证
    downloadYmsCostPayCert(flag) {
      let list = []
      if (flag === 'all') {
        list = this.selectedRowKeys
      } else {
        list.push(this.currentCertRecord.id)
      }
      downloadCertById({
        list,
        type: 7
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.table.refresh(false)
            if (flag !== 'all') {
              this.previewYmsCostPayCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    downloadYmsAmortizeCostPayCert(flag) {
      let list = []
      if (flag === 'all') {
        list = this.selectedRowKeys
      } else {
        list.push(this.currentCertRecord.id)
      }
      downloadCertById({
        list,
        type: 8
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.$refs.table.refresh(false)
            if (flag !== 'all') {
              this.previewYmsAmortizeCostPayCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    printCert() {
      this.certPrintData = this.currentCert.cert_info_list
      this.certPrintName = '付款凭证'
      this.printCertModelVisible = true
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    delCertInfo(type) {
      delCert({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('删除凭证成功')
          if (type === 1) {
            this.$refs.table.refresh(false)
            this.previewPayCert(this.currentCertRecord)
          } else if (type === 2) {
            this.$refs.purchaseTable.refresh(false)
            this.previewPurchaseCert(this.currentCertRecord)
          } else if (type === 3) {
            this.$refs.table.refresh(false)
            this.previewYmsCostPayCert(this.currentCertRecord)
          } else if (type === 4) {
            this.$refs.table.refresh(false)
            this.previewYmsAmortizeCostPayCert(this.currentCertRecord)
          }
        }
      })
    },
    handleConfirm(type) {
      certConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证确认成功')
          if (type === 1) {
            this.$refs.table.refresh(false)
            this.previewPayCert(this.currentCertRecord)
          } else if (type === 2) {
            this.$refs.purchaseTable.refresh(false)
            this.previewPurchaseCert(this.currentCertRecord)
          } else if (type === 3) {
            this.$refs.table.refresh(false)
            this.previewYmsCostPayCert(this.currentCertRecord)
          } else if (type === 4) {
            this.$refs.table.refresh(false)
            this.previewYmsAmortizeCostPayCert(this.currentCertRecord)
          }
        }
      })
    },
    handleUnConfirm(type) {
      certUnConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证反确认成功')
          if (type === 1) {
            this.$refs.table.refresh(false)
            this.previewPayCert(this.currentCertRecord)
          } else if (type === 2) {
            this.$refs.purchaseTable.refresh(false)
            this.previewPurchaseCert(this.currentCertRecord)
          } else if (type === 3) {
            this.$refs.table.refresh(false)
            this.previewYmsCostPayCert(this.currentCertRecord)
          } else if (type === 4) {
            this.$refs.table.refresh(false)
            this.previewYmsAmortizeCostPayCert(this.currentCertRecord)
          }
        }
      })
    },
    transCertStatus(status) {
      switch (status) {
        case 1:
          return '待确认'
        case 2:
          return '已确认'
        case 3:
          return '已下载'
        default:
          return ''
      }
    },
    purchaseInfo() {
      this.purchaseModelVisible = true
      this.purchaseQueryParam = {
        cert_status: undefined
      }
    },
    onPurchaseDateChange(date) {
      if (date[0]) {
        this.purchaseQueryParam['purchase_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.purchaseQueryParam['purchase_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.purchaseQueryParam['purchase_start_date'] = null
        this.purchaseQueryParam['purchase_end_date'] = null
      }
    },
    onSelectPurchaseChange(selectedRowKeys, selectedRows) {
      this.selectedPurchaseRowKeys = selectedRowKeys
      this.selectedPurchaseRows = selectedRows
    }
  }
}
</script>

<style>
.margin-left15 {
  margin-left: 15px;
}
.cert_wait_confirm_color {
  color: #ffda91;
}
.cert_confirm_color {
  color: #8bc7ff;
}
.cert_dowload_color {
  color: #a0ffb0;
}
</style>
